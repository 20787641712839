<template>
    <div>
        <b-row>
            <b-col class="col-lg-6 col-md-8 offset-lg-3 offset-md-2">
                <b-card class="background-perfil position-relative p-2 border">
                </b-card>
                <b-avatar
                    variant="transparent"
                    size="120"
                    class="avatar-perfil"
                ></b-avatar>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-6 col-md-8 offset-lg-3 offset-md-2">
                <b-card>
                    <b-row class="pb-2 border-bottom">
                        <b-col>
                            <div class="mb-1">
                                <label for="nombre">Nombre</label>
                                <b-form-input
                                    id="nombre"
                                    placeholder="Nombre"
                                ></b-form-input>
                            </div>
                            <div class="mb-1">
                                <label for="telefono">Teléfono</label>
                                <b-form-input
                                    id="telefono"
                                    placeholder="Teléfono"
                                ></b-form-input>
                            </div>
                            <div>
                                <label for="identificacion"
                                    >Identificación</label
                                >
                                <b-form-input
                                    id="identificacion"
                                    placeholder="Identificación"
                                ></b-form-input>
                            </div>
                        </b-col>
                        <b-col>
                            <div class="mb-1">
                                <label for="apellidos">Apellidos</label>
                                <b-form-input
                                    id="apellidos"
                                    placeholder="apellidos"
                                ></b-form-input>
                            </div>

                            <div class="mb-1">
                                <label for="correo">Correo</label>
                                <b-form-input
                                    id="correo"
                                    placeholder="Correo"
                                ></b-form-input>
                            </div>
                            <div>
                                <label for="nacimiento"
                                    >Fecha de nacimiento</label
                                >
                                <b-form-datepicker
                                    id="nacimiento"
                                    placeholder="Fecha de nacimiento"
                                ></b-form-datepicker>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="pt-1">
                        <b-col>
                            <label for="contraseña">Contraseña</label>
                            <b-form-input
                                id="contraseña"
                                placeholder="Contraseña"
                            ></b-form-input>
                        </b-col>
                        <b-col>
                            <label for="contraseña">Contraseña</label>
                            <b-form-input
                                id="contraseña"
                                placeholder="Contraseña"
                            ></b-form-input>
                        </b-col>
                    </b-row>
                    <div class="text-right mt-1">
                        <b-button variant="gradient-primary">
                            <feather-icon
                                icon="SaveIcon"
                                size="15"
                            ></feather-icon>
                            Guardar</b-button
                        >
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { ref } from "@vue/composition-api";
export default {
    setup(props, context) {},
};
</script>
<style lang="css">
.background-perfil {
    height: 16em;
    background-image: linear-gradient(47deg, #005eb8, #0078eb);
}
.avatar-perfil {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 5px solid white;
    -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left
                bottom, from(transparent), color-stop(70%, transparent), to(rgba(250, 250, 250, 0.1)));
}
</style>
